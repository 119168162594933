<template>
  <div
    class="bg000_3 w100p h100p left0 top0 position-fixed zindex10002"
    id="prev_modal"
    v-if="isShow"
    @click="previewTap($event)"
  >
    <div class="preview-inner" @click.stop="">
      <div
        class="w100p h100p bgf5f6 oyauto scrollbar_none position-relative p-b-51"
      >
        <el-carousel height="335px">
          <el-carousel-item v-for="(item, index) in prod_imgs" :key="index">
            <img class="w100p h100p" :src="item.url || item" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="bgfff p-l-16 p-r-15 mb10 flex-a-b-c h50">
          <p class="lh45 m-b-0">
            <span class="text-warning fs20 mr10">¥{{ goodsPrice }}</span>
          </p>
          <div class="flex-a-b-c">
            <p class="over_2 fs14 c38 fbold flex1">
              {{ goodsName }}
            </p>
            <div
              class="w46 p-l-30 bgfff contentbox text-right disflex align-cen "
            >
              <img
                src="https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/user/share.png"
                alt=""
                class="w15 h15 m-r-7"
              />
              <b class="fs12 ca8">分享</b>
            </div>
          </div>
        </div>
        <!--规格-->
        <div class="p-l-16 p-r-16 bgfff p-t-16 mb10">
          <p class="fs14 ca8 pb10">类型</p>
          <div class="pb15">
            <div
              v-for="(v, k) in prodSize"
              :key="k"
              class="fs12 c38  pl10 pr10 mr10 mb10 bgf5f6 disinblock lh30 bradius3 bf5f6 size_item dis-inline-block"
              :class="type_id_1 == v.specId ? 'active' : ''"
              @click="type_tap_1(v.specId, k)"
            >
              {{ v.specName }}
            </div>
            <p class="fs14 ca8 pb10">规格</p>
            <div v-if="prodSize[type_index_1].goodSpecModelList">
              <div
                v-for="(v2, k2) in prodSize[type_index_1].goodSpecModelList"
                :key="k2"
                class="fs12 c38  pl10 pr10 mr10 mb10 bgf5f6 disinblock lh30 bradius3 bf5f6 size_item dis-inline-block"
                :class="
                  prodSize[type_index_1].selectId == v2.specId ? 'active' : ' '
                "
                @click="type_tap(type_index_1, k2, v2.specId)"
              >
                {{ v2.specAttribute }}
              </div>
            </div>
          </div>
        </div>
        <div class="bgfff">
          <p class="lh43 textc  fs14 ca8 m-b-0">- 宝贝详情 -</p>
          <div
            class="h270 bgfff diy_prod_detail"
            v-if="edit_html"
            v-html="edit_html"
          ></div>
        </div>
        <p class="lh43 textc fs12 ca8 m-b-0">- 到底了，看看其他的吧 -</p>
      </div>
      <div class="position-absolute prev-bottom bottom left0 w100p ">
        <div class="w100p d-flex bte8">
          <div class="d-flex flex1 bgfff textc">
            <div class="w33p p-t-7">
              <img
                src="https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/user/prod_select.png"
                alt=""
                class="w20 h20"
              />
              <b class="cblue fs12 textc m-t-_2 d-block">产品</b>
            </div>
            <div class="w33p p-t-7">
              <img
                src="https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/user/tel_gray.png"
                alt=""
                class="w20 h20"
              />
              <b class="ca8 fs12 textc m-t-_2 d-block">通话</b>
            </div>
            <div class="w33p p-t-7">
              <img
                src="https://bmrl-card.oss-cn-hangzhou.aliyuncs.com/yimai_photos/user/like_gray.png"
                alt=""
                class="w20 h20"
              />
              <b class="fs12 textc m-t-_2 ca8 d-block">收藏</b>
            </div>
          </div>
          <div
            class="w220 disflex textc fs16 cfff  borderbox bgfff lh40"
            style="padding:5px 15px 5px 5px"
          >
            <span class="w50p bg_line_yellow add_to_card">加入购物车</span>
            <span class="w50p bg_line_orange buy_now m-l-_1">立即购买</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ts-ignore
export default {
  name: "productList",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    prod_imgs: {
      type: Array,
      default() {
        return [];
      },
    },
    prodSize: {
      type: Array,
      default() {
        return [];
      },
    },
    edit_html: {
      type: String,
      default: "",
    },
    goodsName: {
      type: String,
      default: "",
    },
    isFormatMoney: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type_index_1: 0,
      type_index_2: 0,
      type_id_1: 0,
      goodsPrice: 0,
    };
  },

  methods: {
    previewTap(e) {
      console.log(1111);
      this.$emit("close");
    },
    type_tap_1(id_1, index_1) {
      //点击一级分类
      this.type_id_1 = id_1;
      this.type_index_1 = index_1;
    },
    type_tap(index_1, index_2, id) {
      console.log("this.prodSize", this.prodSize);
      this.prodSize[index_1].selectId = id;
      this.type_index_2 = index_2;
      let price = this.prodSize[index_1].goodSpecModelList[index_2].price;
      this.goodsPrice = price;
      this.typeId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg000_3 {
  background: rgba(0, 0, 0, 0.3);
}
.left0 {
  left: 0;
}
.top0 {
  top: 0;
}
.position-fixed {
  position: fixed;
}
.position-relative {
  position: relative;
}
.zindex10002 {
  z-index: 10002;
}
.preview-inner {
  background: url("../../../assets/images/iphone.png") no-repeat;
  background-size: 100% 100%;
  width: 433px;
  height: 884px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow-y: auto;
  padding: 110px 33px 109px 28px;
}
.preview-inner .img-fluid {
  width: 100%;
  height: 355px;
  max-width: initial;
}
.add_to_card {
  border-radius: 20px 0 0 20px;
}
.buy_now {
  border-radius: 0 20px 20px 0;
}
.preview-inner .bottom {
  bottom: 109px;
  padding: 0 31px 0 28px;
}
.p-b-51 {
  padding-bottom: 51px;
}
.carousel-item .container {
  padding: 0;
}
.d-block {
  display: block !important;
}
.preview-inner .img-fluid {
  width: 100%;
  height: 355px;
  max-width: initial;
}
.goods-img,
.img-bg-box {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.p-l-16 {
  padding-left: 16px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-16 {
  padding-right: 16px;
}

.p-r-15 {
  padding-right: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.fs20 {
  font-size: 20px;
}
.over_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fs14 {
  font-size: 14px;
}
.fbold {
  font-weight: bold;
}
.flex1 {
  flex: 1;
}
.w46 {
  width: 46px;
}
.p-l-30 {
  padding-left: 30px;
}
.contentbox {
  box-sizing: content-box;
}
.text-right {
  text-align: right;
}
.disflex {
  display: flex;
}
.align-cen {
  align-items: center;
}
.p-t-16 {
  padding-top: 16px;
}
.ca8 {
  color: #a8a8a8;
}
.position-absolute {
  position: absolute;
}
.preview-inner .bottom {
  bottom: 109px;
  padding: 0 31px 0 28px;
}
.preview-inner .img-fluid {
  width: 100%;
  height: 355px;
  max-width: initial;
}
.w15 {
  width: 15px;
}
.h15 {
  height: 15px;
}
.m-r-7 {
  margin-right: 7px;
}
.bg_line_orange {
  background: linear-gradient(to right, #fe7563, #fd634e);
}
.bg_line_yellow {
  background: linear-gradient(to right, #fcad3d, #ffa133);
}
.fs12 {
  font-size: 12px;
}
.borderbox {
  box-sizing: border-box;
}
.prev-bottom {
  box-sizing: border-box;
  line-height: 1;
  padding: 5px 15px;
}
.m-t-_2 {
  margin-top: -2px;
}
html body .p-b-5 {
  padding-bottom: 5px;
}
.textc {
  text-align: center;
}
.borderbox {
  box-sizing: border-box;
}

.fs16 {
  font-size: 16px;
}
.w220 {
  width: 220px;
}
.lh40 {
  line-height: 40px;
}
.bgfff {
  background: #fff !important;
}
.cfff {
  color: #fff;
}
.disflex {
  display: flex;
}
.p-r-15 {
  padding-right: 15px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-t-7 {
  padding-top: 7px;
}
</style>
